<template>
	<div>
		<!-- top -->
		<div class="top">
			<div class="left">
				<div class="select">
					<el-date-picker
						v-model="date"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						size="mini">
					</el-date-picker>
				  <el-radio-group v-model="radio4" size="mini">
						<el-radio-button label="今日"></el-radio-button>
						<el-radio-button label="昨天"></el-radio-button>
						<el-radio-button label="本月"></el-radio-button>
						<el-radio-button label="上月"></el-radio-button>
					</el-radio-group>
					<el-select 
					v-model="value" 
					placeholder="选择部门"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select
					v-model="value" 
					placeholder="选择员工"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<el-select
					v-model="value" 
					placeholder="选择员工状态"
					size="mini">
						<el-option
							v-for="item in options"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="right">
				<el-button size="mini" type="primary">保存为常用</el-button>
				<el-button size="mini" type="primary">更多</el-button>
			</div>
		</div><!-- end top -->   
		<el-table
			:data="tableData"
			border
			show-summary
			:summary-method="getSummaries"
			style="width: 100%; margin-top: 20px"
			:header-cell-style="{color:'#475669'}"
			size="small">
			<el-table-column
				prop="id"
				label="工号 / 姓名 / 职位"
				sortable
				fixed>
			</el-table-column>
			<el-table-column
				prop="name"
				label="全部商品">
			</el-table-column>
			<el-table-column
				prop="amount1"
				label="数量(个)">
			</el-table-column>
			<el-table-column
				prop="amount2"
				label="记客数">
			</el-table-column>
			<el-table-column
				prop="amount3"
				label="数量占比">
			</el-table-column>
			<el-table-column
				label="外卖业绩"
				align="center">
				<el-table-column
					prop="amount3"
					label="现金业绩">
				</el-table-column>
				<el-table-column
					prop="amount3"
					label="卡金业绩">
				</el-table-column>
				<el-table-column
					prop="amount3"
					label="疗程消耗">
				</el-table-column>
				<el-table-column
					prop="amount3"
					label="总业绩">
				</el-table-column>
				<el-table-column
					prop="amount3"
					label="总提成">
				</el-table-column>
			</el-table-column>
		</el-table>
	  <el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="currentPage4"
			:page-sizes="[100, 200, 300, 400]"
			:page-size="100"
			layout="total, sizes, prev, pager, next, jumper"
			:total="400"
			background>
		</el-pagination>
		<div class="nav">
			<span>数量(个): 0</span>
			<span>记客数: 0</span>
			<span>现金业绩: 0</span>
			<span>卡金业绩: 0</span>
			<span>疗程业绩: 0</span>
			<span>总业绩: 0</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				date: '',
				radio4: '今日',
				timeQuantum: '' ,//选中的时间
				category: '找项目',
				screenData: [
					{ value: '选项1', label: '高级洗吹' },
					{ value: '选项1', label: '高级洗吹' }
				],
				value: '',
				options: [],
				selectValue: '',
				tableData: [
					{ id: '还款', name: '4', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '美发部', name: '4', amount1: '234', amount2: '3.2', amount3: 10 },
					{ id: '12987122', name: '4', amount1: '234', amount2: '3.2', amount3: 10 }
				],
				cities: ['上海', '北京', '广州', '深圳'],
				checkedCities: ['上海', '北京'],
				isIndeterminate: true,
				checkAll: false,
				currentPage4: 1
			}
		},
		methods:{
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				console.log(columns)
				console.log(data)
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '总价';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index] += ' 元';
					} else {
						sums[index] = '--';
					}
				});

				return sums;
			},
			handleCheckedCitiesChange(value){
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},
			handleCheckAllChange(val) {
				this.checkedCities = val ? this.cities : [];
				this.isIndeterminate = false;
			},
			handleSizeChange(){},
			handleCurrentChange(){}
		}
	}
</script>

<style lang="less" scoped>
.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	.left{
		display: flex;
		align-items: center;
		.select{
			display: flex;
			align-items: center;
			.el-input__inner{
				width: 220px !important;
			}
			.el-radio-group{
				margin: 0 10px;
			}
		}
		span{
			font-size: 16px;
		}
		i{
			font-style: normal;
		}
	}
	.right{
		display: flex;
		align-items: center;
		span{
			margin-left: 30px;
			color: #475669;
		}
	}
	
	.prompt{
		margin-left: 20px;
	}
	.center{
		display: flex;
		align-items: center;
	}
	.el-select{
		margin-right: 10px;
	}
}
.el-table {
	font-size: 14px;
}
.el-pagination{
	float: right;
	margin: 20px 0;
}
.nav{
	position: fixed;
  bottom: 0;
	right: 0;
	padding: 20px;
	box-sizing: border-box;
	width: calc(100vw - 160px);
	border-top: 1px solid #e5e9f2;
	span{
		padding: 0 10px;
		border-right: 1px solid #c1c1c1;
	}
	span:last-child{
		border-right: none;
	}
}
</style>
